
html {
  scroll-behavior: smooth;
}

body{
  color: #7087A5;
  font-family: 'Inter', sans-serif;
  background-color: #F1F6FC;
}

p{ font-size:16px; font-weight: 400; color: #7087A5;}
h1, h2, h3{font-weight: 700; color: #283341;}
h1{font-size:40px;}
h2{font-size:32px;}
h3{font-size:20px;}
h4{font-size:18px; font-weight: 500; color: #283341;}
h5{font-size: 14px; font-weight: 600; color: #B7C3D2; text-transform: uppercase;}
h6{font-size: 12px; font-weight: 500;}

a{
  color: inherit;
  text-decoration: underline;
}
a:hover{
  color:#7087a5;
  text-decoration: underline;
}
ul{
  list-style: none;
  padding: 0px;
  margin-bottom: 0px;
}
li{
  padding-top: 6px;
  padding-bottom: 6px;
}

.alt-background{
  background-color: #F8FAFD;
}
.dark-background{
  background-color: #283341;
}

.rounded-sm{
  border-radius: 4px!important;
}
.rounded-lg{
  border-radius: 8px!important;
}



/* Buttons */
.btn{
  border-radius: 8px;
  font-weight: 600;
}
.btn-lg{
  font-size: 16px;
  padding: 11px 32px!important;
}
.btn-primary {
    color: #283341;
    background-color: #FFCD39;
    border-color: #FFCD39;
}
.btn-primary:hover {
    color: #283341;
    background-color: #FFD968;
    border-color: #FFD968;
}
.btn-primary:not(:disabled):not(.disabled):active{
    color: #283341;
    background-color: #FFD968;
    border-color: #FFD968;
}
.btn-primary:focus, .btn-primary:not(:disabled):not(.disabled):active:focus {
    color: #283341;
    background-color: #FFD968;
    border-color: #FFD968;
    box-shadow: 0 0 0 0.2rem rgb(255 205 57 / 30%);
}



/* Inputs */
input[type=text]{
  padding: 10px 16px;
  border-radius: 8px;
  border: 2px solid #E1E8F0;
  background: white;
  width: 100%;
  color: #283341;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
input:focus, input:not(:disabled):not(.disabled):active:focus, input:not(:disabled):not(.disabled):active {
  color: #283341;
  border-color: #FFCD39!important;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
input:focus-visible {
  outline-offset: none;
  outline: none;
}
::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #B7C3D2;
  opacity: 1; /* Firefox */
}
:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #B7C3D2;
}
::-ms-input-placeholder { /* Microsoft Edge */
  color: #B7C3D2;
}

.form-check{
  margin-top: -6px;
}
.form-check-input{
  margin-top: 0.35rem;
}



/* Upgrade to pro form */
.pro-form input[type=radio]{
  display: none;
}
.pro-form .radio-switch {
  text-align: center;
  padding: 6px;
  border-radius: 8px;
  border: 2px solid #41546C;
  background: #344356;
}
.pro-form .radio-switch label {
  background-color: #344356;
  color: #7087A5;
  float: left;
  font-size: 16px;
  margin: 0;
  padding: 20px;
  transition: background-color 0.2s ease;
  width: 50%;
  border-radius: 6px;
  cursor: pointer;
}
.pro-form .radio-switch label:not(:last-child) {
  border-right: 0;
}
.pro-form .radio-switch input:checked + label {
  background-color: #7087A5;
  color: #fff;
  border-radius: 6px;
}

.pro-form input{
  border: 2px solid #41546C;
  background: #344356;
  color: white;
}
.pro-form input:focus, .pro-form input:not(:disabled):not(.disabled):active:focus, .pro-form input:not(:disabled):not(.disabled):active {
  color: white;
  border-color: #FFCD39!important;
}
.pro-form  ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #7087A5;
  opacity: 1; /* Firefox */
}
.pro-form  :-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #7087A5;
}
.pro-form  ::-ms-input-placeholder { /* Microsoft Edge */
  color: #7087A5;
}



/* Panels */




/* Nav */
.nav-link {
    color: #41546C!important;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
    padding-left: 2rem!important;
}
.nav-link:hover {
    color: #7087a5!important;
}

.navbar-light .navbar-toggler {
    color: #7087a5;
    border-color: #E7EFF8;
    border-width: 2px;
    background: #F1F6FC;
}
.navbar-light .navbar-toggler-icon{
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(112, 135, 165, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

.pro-badge{
  text-transform: uppercase;
  font-weight: 600;
  font-size: 12px;
  border-radius: 4px;
  color: #283341;
  background: #FFCD39;
  padding: 2px 4px;
  vertical-align: text-bottom;
}

@media only screen and (max-width: 768px) {
  .navbar-nav{
    border-bottom: 2px solid #E7EFF8;
  }
  .nav-link{
    padding-left: 0px!important;
  }
  .footer-menu, .footer-sub-menu{
    flex-direction: column;
  }
  .footer-sub-menu{
    margin-top: 20px;
    margin-bottom: 40px;
  }
}



/* Editor/output */
#code-container{
  margin-top: 80px
}
.python-editor{
  height: 100vh;
  padding: 32px;
  background: white;
  overflow: hidden;
}

.python-terminal{
  height: 100vh;
  padding: 32px;
  background: #283341;
  color: white;
}